// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/client/request/index.ts"
);
import.meta.hot.lastModified = "1730833216007.7583";
}
// REMIX HMR END

import type { ApiRequestOptions } from '~/api/core/ApiRequestOptions'
import type { ApiResult } from '~/api/core/ApiResult'
import type { OnCancel } from '~/api/core/CancelablePromise'
import { CancelablePromise } from '~/api/core/CancelablePromise'
import type { OpenAPIConfig } from '~/api/core/OpenAPI'
import { log } from '~/logging/log.server'

function isDefined<T>(
  value: T | null | undefined,
): value is Exclude<T, null | undefined> {
  return value !== undefined && value !== null
}

function isString(value: unknown): value is string {
  return typeof value === 'string'
}

function isStringWithValue(value: unknown): value is string {
  return isString(value) && value !== ''
}

function isBlob(value: unknown): value is Blob {
  return (
    typeof value === 'object' &&
    typeof (value as Record<string, unknown>).type === 'string' &&
    typeof (value as Record<string, unknown>).stream === 'function' &&
    typeof (value as Record<string, unknown>).arrayBuffer === 'function' &&
    typeof (value as Record<string, unknown>).constructor === 'function' &&
    typeof (value as Record<string, unknown>).constructor.name === 'string' &&
    /^(Blob|File|NodeOnDiskFile)$/.test(
      (value as Record<string, unknown>).constructor.name,
    ) &&
    /^(Blob|File|NodeOnDiskFile)$/.test(
      (value as Array<string>)[Symbol.toStringTag as unknown as number],
    )
  )
}

function isFormData(value: unknown): value is FormData {
  return value instanceof FormData
}

function base64(str: string): string {
  try {
    return btoa(str)
  } catch (err) {
    return Buffer.from(str).toString('base64')
  }
}

function getQueryString(params: Record<string, unknown>): string {
  const qs: string[] = []

  const append = (key: string, value: unknown) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
  }

  const process = (key: string, value: unknown) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v)
        })
      } else if (typeof value === 'object') {
        Object.entries(value as Record<string, unknown>).forEach(([k, v]) => {
          process(`${key}[${k}]`, v)
        })
      } else {
        append(key, value)
      }
    }
  }

  Object.entries(params).forEach(([key, value]) => {
    process(key, value)
  })

  if (qs.length > 0) {
    return `?${qs.join('&')}`
  }

  return ''
}

function getUrl(config: OpenAPIConfig, options: ApiRequestOptions): string {
  const encoder = config.ENCODE_PATH || encodeURI

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      /* eslint-disable-next-line no-prototype-builtins */
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]))
      }
      return substring
    })

  const url = `${config.BASE}${path}`
  if (options.query) {
    return `${url}${getQueryString(options.query)}`
  }
  return url
}

function getFormData(options: ApiRequestOptions): FormData | undefined {
  if (options.formData) {
    const formData = new FormData()

    const process = (key: string, value: unknown) => {
      if (isString(value) || isBlob(value)) {
        formData.append(key, value)
      } else {
        formData.append(key, JSON.stringify(value))
      }
    }

    Object.entries(options.formData)
      .filter(([_, value]) => isDefined(value))
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => process(key, v))
        } else {
          process(key, value)
        }
      })

    return formData
  }
  return undefined
}

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

async function getResolver<T>(
  options: ApiRequestOptions,
  resolver?: T | Resolver<T>,
): Promise<T | undefined> {
  if (typeof resolver === 'function') {
    return (resolver as Resolver<T>)(options)
  }
  return resolver
}

async function getHeaders(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
): Promise<Headers> {
  const token = await getResolver(options, config.TOKEN)
  const username = await getResolver(options, config.USERNAME)
  const password = await getResolver(options, config.PASSWORD)
  const additionalHeaders = await getResolver(options, config.HEADERS)

  const headers = Object.entries({
    Accept: 'application/json',
    ...additionalHeaders,
    ...options.headers,
  })
    .filter(([_, value]) => isDefined(value))
    .reduce(
      (h, [key, value]) => ({ ...h, [key]: String(value) }),
      {} as Record<string, string>,
    )

  if (isStringWithValue(token)) {
    headers.Authorization = `Bearer ${token}`
  }

  if (isStringWithValue(username) && isStringWithValue(password)) {
    const credentials = base64(`${username}:${password}`)
    headers.Authorization = `Basic ${credentials}`
  }

  if (options.body) {
    if (options.mediaType) {
      headers['Content-Type'] = options.mediaType
    } else if (isBlob(options.body)) {
      headers['Content-Type'] = options.body.type || 'application/octet-stream'
    } else if (isString(options.body)) {
      headers['Content-Type'] = 'text/plain'
    } else if (!isFormData(options.body)) {
      headers['Content-Type'] = 'application/json'
    }
  }

  return new Headers(headers)
}

function getRequestBody<T>(options: ApiRequestOptions): T | undefined {
  if (options.body) {
    if (options.mediaType?.includes('/json')) {
      return JSON.stringify(options.body) as T
    }
    if (
      isString(options.body) ||
      isBlob(options.body) ||
      isFormData(options.body)
    ) {
      return options.body as T
    }
    return JSON.stringify(options.body) as T
  }
  return undefined
}

function sendRequest(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: BodyInit,
  formData: FormData | undefined,
  headers: Headers,
  onCancel: OnCancel,
): Promise<Response> {
  const controller = new AbortController()

  const req: RequestInit = {
    headers,
    body: body ?? formData,
    method: options.method,
    signal: controller.signal,
  }

  if (config.WITH_CREDENTIALS) {
    req.credentials = config.CREDENTIALS
  }

  onCancel(() => controller.abort())

  return fetch(url, req)
}

function getResponseHeader(
  response: Response,
  responseHeader?: string,
): string | undefined {
  if (responseHeader) {
    const content = response.headers.get(responseHeader)
    if (isString(content)) {
      return content
    }
  }
  return undefined
}

async function getResponseBody<T>(response: Response): Promise<T | undefined> {
  if (response.status !== 204) {
    try {
      const contentType = response.headers.get('Content-Type')
      if (contentType) {
        const isJSON = contentType.toLowerCase().startsWith('application/json')
        if (isJSON) {
          return (await response.json()) as T
        }
        return (await response.text()) as T
      }
    } catch (error) {
      log.error(error)
    }
  }
  return undefined
}

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws Response
 */
export function request<T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  catchErrorCodes: (
    config: OpenAPIConfig,
    options: ApiRequestOptions,
    result: ApiResult,
  ) => void,
): CancelablePromise<T> {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = getUrl(config, options)
      const formData = getFormData(options)
      const body = getRequestBody<T>(options)
      const headers = await getHeaders(config, options)

      if (!onCancel.isCancelled) {
        const response = await sendRequest(
          config,
          options,
          url,
          body as BodyInit,
          formData,
          headers,
          onCancel,
        )
        const responseBody = await getResponseBody(response)
        const responseHeader = getResponseHeader(
          response,
          options.responseHeader,
        )

        const result: ApiResult = {
          url,
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          body: responseHeader ?? responseBody,
        }

        catchErrorCodes(config, options, result)

        resolve(result.body as T)
      }
    } catch (error) {
      reject(error)
    }
  })
}
